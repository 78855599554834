import React, { Component } from "react";
import { connect } from "react-redux";

import { setUsers, unsetUsers } from "../store/users/actions";
import { setClients, unsetClients } from "../store/clients/actions";
import {
  setBusinesses,
  unsetBusinesses,
} from "../store/business-units/actions";
import { setCampaigns, unsetCampaigns } from "../store/campaigns/actions";

const WithData = (WrappedComponent) => {
  class With extends Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return connect(
    (state) => ({
      users: state.users,
      clients: state.clients,
      businesses: state.businesses,
      campaigns: state.campaigns,
    }),
    (dispatch) => ({
      setUsers: (users) => dispatch(setUsers(users)),
      unsetUsers: () => dispatch(unsetUsers()),
      setClients: (clients) => dispatch(setClients(clients)),
      unsetClients: () => dispatch(unsetClients()),
      setBusinesses: (businesses) => dispatch(setBusinesses(businesses)),
      unsetBusinesses: () => dispatch(unsetBusinesses()),
      setCampaigns: (campaigns) => dispatch(setCampaigns(campaigns)),
      unsetCampaigns: () => dispatch(unsetCampaigns()),
    })
  )(With);
};

export default WithData;
