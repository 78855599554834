import { constants } from "../../app-constants";

const initialState = [];

export const businesses = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_BUSINESSES:
      return action.payload;
    case constants.UNSET_BUSINESSES:
      return [];
    default:
      return state;
  }
};
