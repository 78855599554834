import React from "react";
import { Switch, Route } from "react-router-dom";
import PropTypes from "prop-types";

import Home from "./Home";
import PageNotFound from "../PageNotFound";
import PrivateRoute from "../../hocs/PrivateRoute";
import Create from "./Create";
import Update from "./Update";

class ClientRoutes extends React.Component {
  render() {
    return (
      <Switch>
        <PrivateRoute
          exact
          path={`${this.props.match.path}`}
          component={Home}
        />
        <PrivateRoute
          path={`${this.props.match.path}/add-new`}
          component={Create}
        />
        <PrivateRoute
          path={`${this.props.match.path}/:id`}
          component={Update}
        />

        <Route component={PageNotFound} />
      </Switch>
    );
  }
}

ClientRoutes.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }),
};

export default ClientRoutes;
