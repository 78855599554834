import { constants } from "../../app-constants";

export const setBusinesses = (businesses) => ({
  type: constants.SET_BUSINESSES,
  payload: businesses,
});

export const unsetBusinesses = () => ({
  type: constants.UNSET_BUSINESSES,
});
