// Reducers
export const constants = {
  LOGIN_URL: "/auth/login",

  FETCHING_USER: "FETCHING_USER",
  FETCHING_USER_FAILURE: "FETCHING_USER_FAILURE",
  FETCHING_USER_SUCCESS: "FETCHING_USER_SUCCESS",

  SET_USER: "SET_USER",
  UNSET_USER: "UNSET_USER",
  // AUTH_START: "AUTH_START",

  SET_USERS: "SET_USERS",
  UNSET_USERS: "UNSET_USERS",

  SET_CLIENTS: "SET_CLIENTS",
  UNSET_CLIENTS: "UNSET_CLIENTS",

  SET_BUSINESSES: "SET_BUSINESSES",
  UNSET_BUSINESSES: "UNSET_BUSINESSES",

  SET_CAMPAIGNS: "SET_CAMPAIGNS",
  UNSET_CAMPAIGNS: "UNSET_CAMPAIGNS",

  SET_REGION: "SET_REGION",
  REMOVE_REGION: "REMOVE_REGION",
  UPDATE_REGION: "UPDATE_REGION",
  SET_SUBACCOUNT: "SET_SUBACCOUNT",
  REMOVE_SUBACCOUNT: "REMOVE_SUBACCOUNT",
};
