import React from "react";
import { Link } from "react-router-dom";
import DataListPage from "../../components/DataListPage";
import { businessUnitsUrl } from "../../config";
import WithData from "../../hocs/WithData";

const Home = (props) => {
  const pages = [
    { name: "Dashboard", to: "/", current: false },
    { name: "Business Units", to: "#", current: true },
  ];

  return (
    <DataListPage
      pages={pages}
      dataUrl={businessUnitsUrl}
      name="business units"
      createPage="/business-units/add-new"
      setGlobalData={props.setBusinesses}
    >
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              NAME
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              COMPANY
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              SLACK CHANNEL ID
            </th>

            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only"></span>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {props.businesses.map((campaign, i) => (
            <tr key={i}>
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                <div className="text-gray-900">{campaign.name}</div>
              </td>

              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <div className="text-gray-900">
                  {campaign.client && campaign.client.name}
                </div>
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <div className="text-gray-900">{campaign.slack_channel_id}</div>
              </td>

              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                <Link
                  to={`/business-units/${campaign.id}`}
                  className="text-indigo-600 hover:text-indigo-900"
                >
                  Edit
                  <span className="sr-only">, {campaign.name}</span>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </DataListPage>
  );
};

export default WithData(Home);
