import React from "react";
import { Switch, Route } from "react-router-dom";
import PropTypes from "prop-types";

import LoginPage from "./LoginPage";
import ConfirmPasswordReset from "./ConfirmPasswordReset";
import PasswordResetRequest from "./PasswordResetRequest";
import PageNotFound from "../PageNotFound";

class AuthRoutes extends React.Component {
  render() {
    return (
      <Switch>
        <Route
          exact
          path={`${this.props.match.path}/login`}
          render={(props) => <LoginPage {...props} />}
        />
        <Route
          path={`${this.props.match.path}/password-reset`}
          component={PasswordResetRequest}
        />
        <Route
          path={`${this.props.match.path}/password-reset-confirm`}
          component={ConfirmPasswordReset}
        />
        <Route component={PageNotFound} />
      </Switch>
    );
  }
}
AuthRoutes.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }),
};

export default AuthRoutes;
