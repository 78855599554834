// import axios from "axios";
import store from "../store";
import { constants } from "../app-constants";

import { message, notification } from "antd";

export const rootUrl = process.env.REACT_APP_API_BASE;
export const baseUrl = rootUrl + "/api/v1/";
export const imageUrl = `https://${process.env.REACT_APP_IMAGE_BASE_URL}.nyc3.digitaloceanspaces.com/media/`;

// auth endpoints
export const baseAuth = baseUrl + "auth/";
export const loginUrl = baseUrl + "login";
export const profileUrl = baseUrl + "profile";
export const logoutUrl = baseAuth + "logout/";
export const signupUrl = baseAuth + "signup/";
export const authTokenUrl = baseAuth + "signup/token-auth/";
export const verifyAccountUrl = baseAuth + "signup/verify-email/";
export const passwordChangeUrl = baseAuth + "password/change/";
export const refreshTokenUrl = baseAuth + "refresh-token/";
// export const userUrl = baseAuth + "user/";

export const clientsUrl = baseUrl + "clients";
export const usersUrl = baseUrl + "users";
export const passwordResetUrl = usersUrl + "/password-reset";
export const passwordResetConfirmUrl = usersUrl + "/confirm-password-reset";
export const OTPUrl = usersUrl + "/send-otp";
export const OTPVerificationUrl = usersUrl + "/verify-otp";

export const campaignsUrl = baseUrl + "campaigns";
export const businessUnitsUrl = baseUrl + "businessunits";
export const texauCampaignsUrl = baseUrl + "texau";

export const getHeaders = () => {
  // axios.defaults.xsrfCookieName = "csrftoken";
  // axios.defaults.xsrfHeaderName = "X-CSRFToken";

  const authUser = localStorage.getItem("web.redwhaleUser")
    ? JSON.parse(localStorage.getItem("web.redwhaleUser"))
    : "";
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",

    Authorization: authUser ? "Bearer " + authUser.token : "Bearer",
  };
  return headers;
};

export const getFormHeaders = () => {
  // axios.defaults.xsrfCookieName = "csrftoken";
  // axios.defaults.xsrfHeaderName = "X-CSRFToken";

  const authUser = localStorage.getItem("web.redwhaleUser")
    ? JSON.parse(localStorage.getItem("web.redwhaleUser"))
    : "";
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: authUser ? "Bearer " + authUser.token : "",
  };
  return headers;
};

export const successMessage = (msg) => {
  message.success(msg);
};

export const errorMessage = (msg) => {
  message.error(msg);
};

export const warningMessage = (msg) => {
  message.warning(msg);
};

export const successNotification = (msg) => {
  notification.success({
    message: msg,
    description: msg,
  });
};

export const errorNotification = (msg) => {
  notification.error({
    message: msg,
    description: msg,
  });
};

export const warningNotification = (msg) => {
  notification.warning({
    message: msg,
    description: msg,
  });
};

export const infoNotification = (msg) => {
  notification.info({
    message: msg,
    description: msg,
  });
};

export const errorToast = (toast, message, error, props) => {
  if (error.response) {
    if (error.response.status === 401) {
      toast.info("Session Expired, Login Again", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
      // remove expired creds
      store.dispatch({
        type: constants.REMOVE_USER,
      });
      localStorage.removeItem("web.redwhaleUser");
      store.dispatch({
        type: constants.REMOVE_SETTINGS,
      });
      localStorage.removeItem("settings");
      // go to login page
      props.history.push("/auth/login");
    } else {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  } else {
    toast.error("can't connect to server, check internet connection.", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    });
  }
};

export const successToast = (toast, message) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
  });
};

export const infoToast = (toast, message) => {
  toast.info(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
  });
};
