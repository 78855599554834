import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";

// import PrivateRoute from "./hocs/PrivateRoute";

import DashboardRoutes from "./pages";
import AuthRoutes from "./pages/auth";
import PageNotFound from "./pages/PageNotFound";

import "./styles/style.css";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/auth" component={AuthRoutes} />
        <Route path="/" component={DashboardRoutes} />
        <Route component={PageNotFound} />
      </Switch>
    </Router>
  );
};

App.propTypes = {
  setExchangeRate: PropTypes.func,
};

export default App;
