import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import PrivateRoute from "../../hocs/PrivateRoute";
import Home from "./Home";
import Create from "./Create";
import PageNotFound from "../PageNotFound";

const TexauRoutes = (props) => {
  return (
    <React.Fragment>
      <Switch>
        <PrivateRoute exact path={props.match.path} component={Home} />
        <PrivateRoute path={`${props.match.path}/add-new`} component={Create} />
        {/* <PrivateRoute
          path={`${props.match.path}clients`}
          component={ClientRoutes}
        />
        <PrivateRoute
          path={`${props.match.path}texau`}
          component={TexauRoutes}
        /> */}
        <Route component={PageNotFound} />
      </Switch>
    </React.Fragment>
  );
};

TexauRoutes.propTypes = {
  match: PropTypes.object,
};

export default withRouter(TexauRoutes);
