import { constants } from "../../app-constants";

export const setClients = (clients) => ({
  type: constants.SET_CLIENTS,
  payload: clients,
});

export const unsetClients = () => ({
  type: constants.UNSET_CLIENTS,
});
