import React, { Component } from "react";
import { connect } from "react-redux";

import { setUser, unsetUser } from "../store/users/actions";

const WithUser = (WrappedComponent) => {
  class With extends Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return connect(
    (state) => ({
      authUser: state.authUser,
    }),
    (dispatch) => ({
      setUser: (user) => dispatch(setUser(user)),
      unsetUser: () => dispatch(unsetUser()),
    })
  )(With);
};

export default WithUser;
