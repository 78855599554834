import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "react-tippy";

const BasicTooltip = (props) => {
  return (
    <React.Fragment>
      <Tooltip
        size="big"
        title="Welcome to React"
        position="right"
        trigger="mouseenter click"
        html={
          <div style={{ textAlign: "left" }}>
            <div style={{ paddingBottom: ".5rem" }}>{props.label}</div>
            {props.label2 ? <div>{props.label2}</div> : null}
          </div>
        }
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clipRule="evenodd"
          />
        </svg>
      </Tooltip>
    </React.Fragment>
  );
};

BasicTooltip.propTypes = {
  label: PropTypes.string,
  label2: PropTypes.string,
};

export default BasicTooltip;
