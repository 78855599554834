import React from 'react'
import { Link } from 'react-router-dom'

const PageNotFound = () => (
  <div>
    <div className="absolute-center align--center flex-column mt-15rem wrapper">
      <h1 className="text--black text--404 mb-2rem">404</h1>
      <h4 className="mb-1rem">The page you’re looking for does not exist</h4>
      <p className="mb-2rem text--muted">
        Sorry about this, let’s get you to some place better than this
      </p>
      <Link to="/">
        <button className="btn btn--custom-one btn--width-md">Go home</button>
      </Link>
    </div>
  </div>
)

export default PageNotFound
