import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import PageLayout from "./Layouts/PageLayout";
import { errorNotification, getHeaders } from "../config";
import PropTypes from "prop-types";

const DataListPage = (props) => {
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({});
  const [loading, setLoading] = useState([]);

  const getData = async (url) => {
    setLoading(true);
    setData([]);

    await axios
      .get(url ? url : props.dataUrl, { headers: getHeaders() })
      .then((res) => {
        setLoading(false);
        setData(res.data.data);
        props.setGlobalData(res.data.data);
        setMeta({
          total: res.data.meta.total,
          per_page: res.data.meta.per_page,
          last_page: res.data.meta.last_page,
          current_page: res.data.meta.current_page,
          next: res.data.links.next,
          prev: res.data.links.prev,
        });
      })
      .catch((err) => {
        setLoading(false);
        if (err.response && err.response.status < 500) {
          errorNotification(`Error fetching ${props.name}, kindly retry.`);
        } else {
          errorNotification("Oops something went wront, kindly retry");
        }
      });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  return (
    <PageLayout pages={props.pages}>
      <div className="flex flex-col lg:mx-20 my-10">
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                {props.children}
              </div>
            </div>
          </div>
        </div>

        {loading ? (
          <div className=" text-center text-blue-700 p-10">
            <h3 className="text-gray-700">Fetching {props.name}...</h3>
            <LoadingOutlined className="text-6xl mt-10" />
          </div>
        ) : null}

        <div className="bg-white px-4 py-3 flex flex-col items-center justify-between border-t border-gray-200 sm:px-6">
          <div className="flex justify-between gap-10">
            <Link
              to="#"
              onClick={() => (meta.prev ? getData(meta.prev) : {})}
              className="px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Previous{" "}
            </Link>

            {/* {meta.last_page &&
              Array.from(Array(meta.last_page + 1), (v, i) => (
                <Link
                  onClick={() => (meta.prev ? getData(process) : {})}
                  className={`px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 ${
                    i + 1 === meta.current_page ? "hidden" : ""
                  }`}
                >
                  {i + 1}
                </Link>
              ))} */}

            <Link
              to="#"
              onClick={() => (meta.next ? getData(meta.next) : {})}
              className="ml-3 px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Next{" "}
            </Link>
          </div>
          {data.length ? (
            <div className="flex items-center justify-between mt-5">
              <div>
                <p className="text-sm text-gray-700">
                  Showing
                  <span className="font-medium ml-1">1</span>
                  <span className="ml-1">to</span>
                  <span className="font-medium ml-1">{data.length}</span>
                  <span className="ml-1">of</span>
                  <span className="font-medium ml-1">{data.length}</span>
                  results
                </p>
              </div>
            </div>
          ) : null}
        </div>

        <button
          type="button"
          onClick={() => props.history.push(props.createPage)}
          className="bottom-20 right-5 z-auto absolute py-4 px-4 border border-blue-700 shadow-sm text-md font-medium rounded-full text-white bg-blue-700 hover:bg-blue-500 focus:outline-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
    </PageLayout>
  );
};

DataListPage.propTypes = {
  pages: PropTypes.array.isRequired,
  dataUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  createPage: PropTypes.string.isRequired,
  setGlobalData: PropTypes.func.isRequired,
};

export default withRouter(DataListPage);
