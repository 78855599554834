import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import withUser from "./WithUser";

const PrivateRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      authUser.token ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/auth/login",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.any, // Todo: This is not the best, fix asap
  location: PropTypes.object,
  authUser: PropTypes.shape({
    token: PropTypes.string,
  }),
};

export default withUser(PrivateRoute);
