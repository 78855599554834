import React from "react";
import { Link } from "react-router-dom";
import DataListPage from "../../components/DataListPage";
import { usersUrl } from "../../config";
import WithData from "../../hocs/WithData";

const Home = (props) => {
  const pages = [
    { name: "Dashboard", to: "/", current: false },
    { name: "Team Users", to: "#", current: true },
  ];

  return (
    <DataListPage
      pages={pages}
      dataUrl={usersUrl}
      name="team users"
      createPage="/users/add-new"
      setGlobalData={props.setUsers}
    >
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              FULL NAME
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              ROLE
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              COMPANY
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              EMAIL
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              STATUS
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              CRM TYPE
            </th>
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only"></span>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {props.users.map((campaign, i) => (
            <tr key={i}>
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                <div className="flex items-center">
                  <div className="h-10 w-10 flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-md"
                      src={
                        campaign.profile_photo_url
                          ? campaign.profile_photo_url
                          : `https://ui-avatars.com/api/?name=${campaign.full_name}%20${campaign.full_name}&color=7F9CF5&background=EBF4FF`
                      }
                      alt={campaign.full_name}
                      title={campaign.full_name}
                    />
                  </div>
                  <div className="ml-4">
                    <div className="font-medium text-gray-900">
                      {campaign.full_name}
                    </div>
                  </div>
                </div>
              </td>

              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <div className="text-gray-900">{campaign.name}</div>
              </td>

              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <div className="text-gray-900">
                  {campaign.client && campaign.client.name}
                </div>
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <div className="text-gray-900">{campaign.email}</div>
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <span className="inline-flex">
                  {campaign.active ? (
                    <span className="bg-green-100 text-green-800 rounded-full px-2 text-xs font-semibold leading-5">
                      ACTIVE
                    </span>
                  ) : (
                    <span className="bg-red-100 text-red-800 rounded-full px-2 text-xs font-semibold leading-5">
                      INACTIVE
                    </span>
                  )}
                </span>
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {campaign.crm_type}
              </td>

              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                <Link
                  to={`/users/${campaign.uuid}`}
                  className="text-indigo-600 hover:text-indigo-900"
                >
                  Edit
                  <span className="sr-only">, {campaign.name}</span>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </DataListPage>
  );
};

export default WithData(Home);
