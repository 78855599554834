import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
// import { Input } from "antd";
import React, { useEffect, useState } from "react";
import PageLayout from "../../components/Layouts/PageLayout";
import {
  clientsUrl,
  errorNotification,
  successNotification,
  getHeaders,
} from "../../config";
import { FileUploader } from "../../utils";

const Update = (props) => {
  const pages = [
    { name: "Dashboard", to: "/", current: false },
    { name: "Clients", to: "/clients", current: false },
    { name: "Update Client", to: "#", current: true },
  ];

  const [state, setState] = useState({
    full_name: "",
    email: "",
    profile_photo_url: "",
    active: true,
    role: "",
    crm_type: "",
    company_id: "",
    password: "",
    password_confirmation: "",
  });
  const [errorMessage, setErrorMessage] = useState({});
  const [fileLoading, setFileLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clientLoading, setclientLoading] = useState(false);

  const getClient = (id) => {
    setclientLoading(true);
    axios
      .get(`${clientsUrl}/${id}`, { headers: getHeaders() })
      .then((res) => {
        let data = res.data.data;
        setState({
          name: data.name,
          company_legal_name: data.company_legal_name,
          description: data.description,
          logo_url: data.logo_url,
          client_state: data.client_state,
          client_website: data.client_website,
          company_size: data.company_size,
          primary_contact_person: data.primary_contact_person,
          status: data.status,
          activity_channel_id: data.activity_channel_id,
          response_channel_id: data.response_channel_id,
          developer_api_key: data.developer_api_key,
          base_url: data.base_url,
          copper_company_id: data.copper_company_id,
        });
        setclientLoading(false);
      })
      .catch((err) => {
        if (err.response && err.response.status < 500) {
          errorNotification(
            `Error fetching client, ${err.response.data.message}`
          );
          // setErrorMessage({ message: err.response.data.message });
        } else {
          // setErrorMessage({
          //   message: "Oops! Something happened. Please try again.",
          // });
          errorNotification(`Error fetching client, reload to retry.`);
        }
        setclientLoading(false);
      });
  };

  useEffect(() => {
    getClient(props.match.params.id);

    // eslint-disable-next-line
  }, []);

  const handleFileCallback = (err, data) => {
    if (err) {
      setFileLoading(false);
      errorNotification("Upload failed, retry");
      setErrorMessage({
        ...errorMessage,
        message: "Error uploading image, kindly retry",
      });
      console.log(err);
    }
    if (data) {
      // console.log("data", data);

      setFileLoading(false);
      successNotification("Upload Successful");
      setState({ ...state, logo_url: data.Location });
    }
  };

  const handleFileUpload = (stateName) => (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    setFileLoading(true);

    FileUploader(file, handleFileCallback);

    // remove form field errors
    if (errorMessage[stateName]) {
      setErrorMessage({
        ...errorMessage,
        [stateName]: null,
        message: null,
      });
    } else {
      setErrorMessage({
        ...errorMessage,
        message: null,
      });
    }
  };

  const handleFieldUpdate = (stateName) => (event) => {
    setState({ ...state, [stateName]: event.target.value });

    // remove form field errors
    if (errorMessage[stateName]) {
      setErrorMessage({
        ...errorMessage,
        [stateName]: null,
        message: null,
      });
    } else {
      setErrorMessage({
        ...errorMessage,
        message: null,
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    axios
      .put(clientsUrl + "/" + props.match.params.id, state, {
        headers: getHeaders(),
      })
      .then((res) => {
        setLoading(false);
        successNotification("Client Updated Successfully");
        props.history.push("/clients");
      })
      .catch((err) => {
        setLoading(false);
        if (err.response && err.response.status < 500) {
          setErrorMessage({
            ...err.response.data.errors,
            message: err.response.data.message,
          });
        } else {
          setErrorMessage({
            ...errorMessage,
            message: ["Oops something went wront, kindly retry"],
          });
        }
      });
  };

  return (
    <PageLayout pages={pages}>
      <div className="container py-5 px-5 mx-auto">
        <form
          onSubmit={() => handleSubmit}
          className="shadow overflow-hidden sm:rounded-md mt-6 px-10 py-10 bg-white"
        >
          {clientLoading ? (
            <div className="flex flex-col items-center">
              <div>Fetching client data...</div>{" "}
              <LoadingOutlined className="text-5xl mt-5" />
            </div>
          ) : null}

          <div className="grid grid-cols-2 gap-10 mt-5">
            <div className="">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Company Name
              </label>
              <input
                type="text"
                name="name"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                value={state.name}
                onChange={handleFieldUpdate("name")}
                required
                placeholder=""
              />
              {errorMessage.name
                ? errorMessage.name.map((err, i) => (
                    <p className="text-red-900 mt-2" key={i}>
                      {err}
                    </p>
                  ))
                : null}
            </div>

            <div className="">
              <label
                htmlFor="company_legal_name"
                className="block text-sm font-medium text-gray-700"
              >
                Compnay Legal Name
              </label>
              <input
                type="text"
                name="company_legal_name"
                id="company_legal_name"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                value={state.company_legal_name}
                onChange={handleFieldUpdate("company_legal_name")}
                placeholder=""
              />
              {errorMessage.company_legal_name
                ? errorMessage.company_legal_name.map((err, i) => (
                    <p className="text-red-900 mt-2" key={i}>
                      {err}
                    </p>
                  ))
                : null}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-10 mt-5">
            <div className="">
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700"
              >
                Description
              </label>
              <input
                type="text"
                name="description"
                id="description"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                value={state.description}
                onChange={handleFieldUpdate("description")}
                placeholder=""
              />
              {errorMessage.description
                ? errorMessage.description.map((err, i) => (
                    <p className="text-red-900 mt-2" key={i}>
                      {err}
                    </p>
                  ))
                : null}
            </div>

            <div className="">
              <label
                htmlFor="client_website"
                className="block text-sm font-medium text-gray-700"
              >
                Client Website
              </label>
              <input
                type="text"
                name="client_website"
                id="client_website"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                required
                value={state.client_website}
                onChange={handleFieldUpdate("client_website")}
                placeholder=""
              />
              {errorMessage.client_website
                ? errorMessage.client_website.map((err, i) => (
                    <p className="text-red-900 mt-2" key={i}>
                      {err}
                    </p>
                  ))
                : null}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-10 mt-5">
            <div className="">
              <label
                htmlFor="client_state"
                className="block text-sm font-medium text-gray-700"
              >
                State
              </label>
              <input
                type="text"
                name="client_state"
                id="client_state"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                value={state.client_state}
                onChange={handleFieldUpdate("client_state")}
                placeholder=""
              />
              {errorMessage.client_state
                ? errorMessage.client_state.map((err, i) => (
                    <p className="text-red-900 mt-2" key={i}>
                      {err}
                    </p>
                  ))
                : null}
            </div>

            <div className="">
              <label
                htmlFor="company_size"
                className="block text-sm font-medium text-gray-700"
              >
                Company Size
              </label>
              <input
                type="text"
                name="company_size"
                id="company_size"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                value={state.company_size}
                onChange={handleFieldUpdate("company_size")}
                placeholder=""
              />
              {errorMessage.company_size
                ? errorMessage.company_size.map((err, i) => (
                    <p className="text-red-900 mt-2" key={i}>
                      {err}
                    </p>
                  ))
                : null}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-10 mt-5">
            <div className="">
              <label
                htmlFor="primary_contact_person"
                className="block text-sm font-medium text-gray-700"
              >
                Primary Contact Person
              </label>
              <input
                type="text"
                name="primary_contact_person"
                id="primary_contact_person"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                value={state.primary_contact_person}
                onChange={handleFieldUpdate("primary_contact_person")}
                placeholder=""
              />
              {errorMessage.primary_contact_person
                ? errorMessage.primary_contact_person.map((err, i) => (
                    <p className="text-red-900 mt-2" key={i}>
                      {err}
                    </p>
                  ))
                : null}
            </div>

            <div className="">
              <label
                htmlFor="activity_channel_id"
                className="block text-sm font-medium text-gray-700"
              >
                Activity Channel ID
              </label>
              <input
                type="text"
                name="activity_channel_id"
                id="activity_channel_id"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                value={state.activity_channel_id}
                onChange={handleFieldUpdate("activity_channel_id")}
                placeholder=""
              />
              {errorMessage.activity_channel_id
                ? errorMessage.activity_channel_id.map((err, i) => (
                    <p className="text-red-900 mt-2" key={i}>
                      {err}
                    </p>
                  ))
                : null}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-10 mt-5">
            <div className="">
              <label
                htmlFor="response_channel_id"
                className="block text-sm font-medium text-gray-700"
              >
                Response Channel ID
              </label>
              <input
                type="text"
                name="response_channel_id"
                id="response_channel_id"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                value={state.response_channel_id}
                onChange={handleFieldUpdate("response_channel_id")}
                placeholder=""
              />
              {errorMessage.response_channel_id
                ? errorMessage.response_channel_id.map((err, i) => (
                    <p className="text-red-900 mt-2" key={i}>
                      {err}
                    </p>
                  ))
                : null}
            </div>

            <div className="">
              <label
                htmlFor="developer_api_key"
                className="block text-sm font-medium text-gray-700"
              >
                Developer API Key
              </label>
              <input
                type="text"
                name="developer_api_key"
                id="developer_api_key"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                value={state.developer_api_key}
                onChange={handleFieldUpdate("developer_api_key")}
                placeholder=""
              />
              {errorMessage.developer_api_key
                ? errorMessage.developer_api_key.map((err, i) => (
                    <p className="text-red-900 mt-2" key={i}>
                      {err}
                    </p>
                  ))
                : null}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-10 mt-5">
            <div className="">
              <label
                htmlFor="base_url"
                className="block text-sm font-medium text-gray-700"
              >
                Base URL
              </label>
              <input
                type="text"
                name="base_url"
                id="base_url"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                value={state.base_url}
                onChange={handleFieldUpdate("base_url")}
                placeholder=""
              />
              {errorMessage.base_url
                ? errorMessage.base_url.map((err, i) => (
                    <p className="text-red-900 mt-2" key={i}>
                      {err}
                    </p>
                  ))
                : null}
            </div>

            <div className="">
              <label
                htmlFor="copper_company_id"
                className="block text-sm font-medium text-gray-700"
              >
                Copper Company ID
              </label>
              <input
                type="text"
                name="copper_company_id"
                id="copper_company_id"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                value={state.copper_company_id}
                onChange={handleFieldUpdate("copper_company_id")}
                placeholder=""
              />
              {errorMessage.copper_company_id
                ? errorMessage.copper_company_id.map((err, i) => (
                    <p className="text-red-900 mt-2" key={i}>
                      {err}
                    </p>
                  ))
                : null}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-10 mt-5">
            <div className="md:mt-5" id="">
              <label
                htmlFor="show_follow_up"
                className="inline-flex text-sm font-medium text-gray-700 "
              >
                Choose Profile Picture{" "}
                {fileLoading ? (
                  <small className="ml-5 bg-green-200 px-2 py-1 mb-1 rounded-md text-green-900">
                    Uploading .. <LoadingOutlined />
                  </small>
                ) : null}
              </label>
              <input
                type="file"
                name="logo_url"
                id=""
                onChange={handleFileUpload("logo_url")}
                className="mr-5 block py-2 px-3"
              />{" "}
              {errorMessage.logo_url
                ? errorMessage.logo_url.map((err, i) => (
                    <p className="text-red-900 mt-2" key={i}>
                      {err}
                    </p>
                  ))
                : null}
            </div>

            <div className="md:mt-5" id="">
              <label
                htmlFor="status"
                className="inline-flex text-sm font-medium text-gray-700 "
              >
                <input
                  type="checkbox"
                  name="status"
                  id="status"
                  onChange={handleFieldUpdate("status")}
                  checked={state.status}
                  className="mr-3 block py-2 px-3"
                />{" "}
                Active
              </label>
            </div>
          </div>

          {errorMessage.message ? (
            <p style={{ color: "red", marginTop: "1em" }}>
              {errorMessage.message}
            </p>
          ) : null}

          <div className="py-3 text-right mt-4 flex flex-row items-center gap-5 justify-end">
            <button
              type="button"
              className="inline-flex justify-center py-2 px-4 border border-gray-500 shadow-sm text-sm font-medium rounded-md text-gray-500 bg-white hover:bg-gray-200 focus:outline-none"
              onClick={() => props.history.push("/clients")}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleSubmit}
              disabled={loading ? true : false}
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Update Client {loading ? <LoadingOutlined /> : null}
            </button>
          </div>
        </form>
      </div>
    </PageLayout>
  );
};

export default Update;
