import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
// import { Input } from "antd";
import React, { useEffect, useState } from "react";
import PageLayout from "../../components/Layouts/PageLayout";
import {
  clientsUrl,
  errorNotification,
  successNotification,
  getHeaders,
  usersUrl,
} from "../../config";
import { FileUploader } from "../../utils";

const Create = (props) => {
  const pages = [
    { name: "Dashboard", to: "/", current: false },
    { name: "Team Users", to: "/users", current: false },
    { name: "Add New", to: "#", current: true },
  ];

  const crm_types = [
    { label: "Hubspot", value: "hubspot" },
    { label: "Pipedrive", value: "pipedrive" },
    { label: "Copper", value: "copper" },
  ];

  const role_types = [
    { label: "User", value: "User" },
    { label: "Admin", value: "Admin" },
    { label: "Manager", value: "Manager" },
  ];

  const [clients, setClients] = useState([]);
  const [state, setState] = useState({
    full_name: "",
    email: "",
    profile_photo_url: "",
    active: true,
    role: "",
    crm_type: "",
    company_id: "",
    password: "",
    password_confirmation: "",
  });
  const [errorMessage, setErrorMessage] = useState({});
  const [clientLoading, setClientLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setClientLoading(true);
    axios
      .get(clientsUrl, { headers: getHeaders() })
      .then((res) => {
        setClients(res.data.data);
        setClientLoading(false);
      })
      .catch((err) => {
        if (err.response && err.response.status < 500) {
          errorNotification(
            `Error getting clients, ${err.response.data.message}`
          );
          // setErrorMessage({ message: err.response.data.message });
        } else {
          // setErrorMessage({
          //   message: "Oops! Something happened. Please try again.",
          // });
          errorNotification(`Error getting clients, reload to retry.`);
        }
        setClientLoading(false);
      });
  }, []);

  const handleSelectUpdate = (stateName) => (event) => {
    setState({ ...state, [stateName]: event.target.value });

    // remove form field errors
    if (errorMessage[stateName]) {
      setErrorMessage({
        ...errorMessage,
        [stateName]: null,
        message: null,
      });
    } else {
      setErrorMessage({
        ...errorMessage,
        message: null,
      });
    }
  };

  const handleFileCallback = (err, data) => {
    if (err) {
      setFileLoading(false);
      errorNotification("Upload failed, retry");
      setErrorMessage({
        ...errorMessage,
        message: "Error uploading image, kindly retry",
      });
      console.log(err);
    }
    if (data) {
      // console.log("data", data);

      setFileLoading(false);
      successNotification("Upload Successful");
      setState({ ...state, profile_photo_url: data.Location });
    }
  };

  const handleFileUpload = (stateName) => (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    setFileLoading(true);

    FileUploader(file, handleFileCallback);
    // if (resp.status === 200) {
    //   setFileLoading(false);
    //   successNotification("Upload Successful");
    //   setState({ ...state, [stateName]: resp.url });
    //   console.log(resp);
    // } else {
    //   setFileLoading(false);
    //   errorNotification("Upload failed, retry");
    //   console.log(resp);
    // }

    // remove form field errors
    if (errorMessage[stateName]) {
      setErrorMessage({
        ...errorMessage,
        [stateName]: null,
        message: null,
      });
    } else {
      setErrorMessage({
        ...errorMessage,
        message: null,
      });
    }
  };

  const handleFieldUpdate = (stateName) => (event) => {
    setState({ ...state, [stateName]: event.target.value });

    // remove form field errors
    if (errorMessage[stateName]) {
      setErrorMessage({
        ...errorMessage,
        [stateName]: null,
        message: null,
      });
    } else {
      setErrorMessage({
        ...errorMessage,
        message: null,
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    // let data = {
    //   ...state,
    //   active: state.active === "true" ? true : false,
    // };

    if (state.password === state.password_confirmation) {
      axios
        .post(usersUrl, state, { headers: getHeaders() })
        .then((res) => {
          setLoading(false);
          successNotification("User Created Successfully");
          props.history.push("/users");
        })
        .catch((err) => {
          setLoading(false);
          if (err.response && err.response.status < 500) {
            setErrorMessage({
              ...err.response.data.errors,
              message: err.response.data.message,
            });
          } else {
            setErrorMessage({
              ...errorMessage,
              message: ["Oops something went wront, kindly retry"],
            });
          }
        });
    } else {
      setLoading(false);
      setErrorMessage({ message: "passwords don't match" });
    }
  };

  return (
    <PageLayout pages={pages}>
      <div className="container py-5 px-5 mx-auto">
        <form
          onSubmit={() => handleSubmit}
          className="shadow overflow-hidden sm:rounded-md mt-6 px-10 py-10 bg-white"
        >
          <div className="grid grid-cols-2 gap-10 mt-5">
            <div className="">
              <label
                htmlFor="full name"
                className="block text-sm font-medium text-gray-700"
              >
                Full Name
              </label>
              <input
                type="text"
                name="full name"
                id="full name"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                value={state.full_name}
                onChange={handleFieldUpdate("full_name")}
                required
                placeholder="john doe"
              />
              {errorMessage.full_name
                ? errorMessage.full_name.map((err, i) => (
                    <p className="text-red-900 mt-2" key={i}>
                      {err}
                    </p>
                  ))
                : null}
            </div>

            <div className="">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                required
                value={state.email}
                onChange={handleFieldUpdate("email")}
                placeholder="you@yourcompany.com"
              />
              {errorMessage.email
                ? errorMessage.email.map((err, i) => (
                    <p className="text-red-900 mt-2" key={i}>
                      {err}
                    </p>
                  ))
                : null}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-10 mt-5">
            <div>
              <label
                htmlFor="client_name"
                className="block text-sm font-medium text-gray-700"
              >
                Company{" "}
                {clientLoading ? (
                  <small className="ml-5 bg-green-200 px-2 py-1 mb-1 rounded-md text-green-900">
                    Loading .. <LoadingOutlined />
                  </small>
                ) : null}
              </label>
              <select
                id="client_name"
                name="client_name"
                required
                onChange={handleSelectUpdate("company_id")}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="">---- Choose ----</option>
                {clients.map((client) => (
                  <option key={client.id} value={client.id}>
                    {client.name}
                  </option>
                ))}
              </select>

              {errorMessage.company_id
                ? errorMessage.company_id.map((err, i) => (
                    <p className="text-red-900 mt-2" key={i}>
                      {err}
                    </p>
                  ))
                : null}
            </div>

            <div>
              <label
                htmlFor="sales_rep"
                className="block text-sm font-medium text-gray-700"
              >
                CRM TYPE
              </label>
              <select
                id="sales_rep"
                name="sales_rep"
                required
                onChange={handleSelectUpdate("crm_type")}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="">---- Choose ----</option>
                {crm_types.map((crm) => (
                  <option key={crm.value} value={crm.value}>
                    {crm.label}
                  </option>
                ))}
              </select>

              {errorMessage.crm_type
                ? errorMessage.crm_type.map((err, i) => (
                    <p className="text-red-900 mt-2" key={i}>
                      {err}
                    </p>
                  ))
                : null}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-10 mt-5">
            <div>
              <label
                htmlFor="campaign"
                className="block text-sm font-medium text-gray-700"
              >
                User Role
              </label>
              <select
                id="campaign"
                name="campaign"
                required
                onChange={handleSelectUpdate("role")}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="">---- Choose ----</option>
                {role_types.map((role) => (
                  <option key={role.value} value={role.value}>
                    {role.label}
                  </option>
                ))}
              </select>

              {errorMessage.role
                ? errorMessage.role.map((err, i) => (
                    <p className="text-red-900 mt-2" key={i}>
                      {err}
                    </p>
                  ))
                : null}
            </div>

            <div className="md:mt-10" id="show_follow_up_section">
              <label
                htmlFor="show_follow_up"
                className="inline-flex text-sm font-medium text-gray-700 "
              >
                <input
                  type="checkbox"
                  name="show_follow_up"
                  id="show_follow_up"
                  onChange={handleFieldUpdate("active")}
                  // value={state.active}
                  checked={state.active}
                  className="mr-3 block py-2 px-3"
                />{" "}
                Active
              </label>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-10 mt-5">
            <div className="md:mt-10" id="">
              <label
                htmlFor="show_follow_up"
                className="inline-flex text-sm font-medium text-gray-700 "
              >
                Choose Profile Picture{" "}
                {fileLoading ? (
                  <small className="ml-5 bg-green-200 px-2 py-1 mb-1 rounded-md text-green-900">
                    Uploading .. <LoadingOutlined />
                  </small>
                ) : null}
              </label>
              <input
                type="file"
                name="show_follow_up"
                id=""
                onChange={handleFileUpload("profile_photo_url")}
                className="mr-5 block py-2 px-3"
              />{" "}
              {errorMessage.profile_photo_url
                ? errorMessage.profile_photo_url.map((err, i) => (
                    <p className="text-red-900 mt-2" key={i}>
                      {err}
                    </p>
                  ))
                : null}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-10 mt-5">
            <div className="">
              <label
                htmlFor="full name"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>

              <input
                type="text"
                name="password"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                required
                onChange={handleFieldUpdate("password")}
                value={state.password}
              />

              {errorMessage.password
                ? errorMessage.password.map((err, i) => (
                    <p className="text-red-900 mt-2" key={i}>
                      {err}
                    </p>
                  ))
                : null}
            </div>

            <div className="">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Confirm Password
              </label>

              <input
                type="text"
                name="password_confirmation"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                required
                onChange={handleFieldUpdate("password_confirmation")}
                value={state.password_confirmation}
              />

              {errorMessage.password_confirmation
                ? errorMessage.password_confirmation.map((err, i) => (
                    <p className="text-red-900 mt-2" key={i}>
                      {err}
                    </p>
                  ))
                : null}
            </div>
          </div>

          {errorMessage.message ? (
            <p style={{ color: "red", marginTop: "1em" }}>
              {errorMessage.message}
            </p>
          ) : null}

          <div className="py-3 text-right mt-4 flex flex-row items-center gap-5 justify-end">
            <button
              type="button"
              className="inline-flex justify-center py-2 px-4 border border-gray-500 shadow-sm text-sm font-medium rounded-md text-gray-500 bg-white hover:bg-gray-200 focus:outline-none"
              onClick={() => props.history.push("/users")}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleSubmit}
              disabled={loading ? true : false}
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save User {loading ? <LoadingOutlined /> : null}
            </button>
          </div>
        </form>
      </div>
    </PageLayout>
  );
};

export default Create;
