import { createStore, compose, combineReducers, applyMiddleware } from "redux";

import thunk from "redux-thunk";

import { authUser, users } from "./users/reducers";
import { clients } from "./clients/reducers";
import { businesses } from "./business-units/reducers";
import { campaigns } from "./campaigns/reducers";
// import { payments, exchangeRate } from 'components/Payments/reducers'
// import { search } from 'components/Search/reducers'
// import { region } from 'components/Region/reducers'

const reducers = {
  // products,
  authUser,
  users,
  clients,
  businesses,
  campaigns,
};

const reducer = combineReducers(reducers);

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer, composeEnhancer(applyMiddleware(thunk)));

export default store;
