import React from "react";
import PageLayout from "../components/Layouts/PageLayout";
import userCard from "../assets/images/dashboard/active-users-card.svg";
import clientCard from "../assets/images/dashboard/active-clients-card.svg";
import campCard from "../assets/images/dashboard/active-campaigns-card.svg";
import buCard from "../assets/images/dashboard/active-business-units-card.svg";
import BarChart from "../components/charts/bar";
import { Link } from "react-router-dom";

const Dashboard = (props) => {
  const pages = [{ name: "Dashboard", to: "/", current: true }];
  const totalUsers = 0;
  const totalClients = 0;
  const topActiveSalesMembers = [];
  const totalBusinessUnits = 0;
  const totalCampaigns = 0;
  const startDate = "22 Mar, 2022";
  const endDate = "22 Mar, 2022";
  const allClients = [];
  const activeCampaigns = 0;
  const campaigns = {};
  const totalLeads = 0;
  const totalMessages = 0;
  const topActiveLeads = [];

  return (
    <PageLayout pages={pages}>
      <div className="mt-8">
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-2xl leading-6 font-bold text-black">
            Quick Stats
          </h2>
          {"Admin" ? (
            <>
              <a
                href="clients.create"
                className="inline-flex items-center p-3 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-800 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 fixed bottom-6 right-6"
              >
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                  />
                </svg>
              </a>
              <div
                className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4"
                x-max="1"
              >
                {/* <!-- Card --> */}
                <div
                  className=" overflow-hidden shadow rounded-lg"
                  style={{
                    backgroundImage: `url(${userCard})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <div className="p-5">
                    <div className="flex items-center">
                      <div className="flex-shrink-0"></div>
                      <div className="ml-5 w-0 flex-1">
                        <dl>
                          <dt className="text-sm font-bold text-white truncate uppercase">
                            Active Users
                          </dt>
                          <dd>
                            <div className="text-lg font-bold text-white">
                              {totalUsers}
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="overflow-hidden shadow rounded-lg"
                  style={{
                    backgroundImage: `url(${clientCard})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <div className="p-5">
                    <div className="flex items-center">
                      <div className="flex-shrink-0"></div>
                      <div className="ml-5 w-0 flex-1">
                        <dl>
                          <dt className="text-sm font-bold text-white truncate uppercase">
                            Active Clients
                          </dt>
                          <dd>
                            <div className="text-lg font-bold text-white">
                              {totalClients}
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="overflow-hidden shadow rounded-lg"
                  style={{
                    backgroundImage: `url(${campCard})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <div className="p-5">
                    <div className="flex items-center">
                      <div className="flex-shrink-0"></div>
                      <div className="ml-5 w-0 flex-1">
                        <dl>
                          <dt className="text-sm font-bold text-white truncate uppercase">
                            Active Campaigns
                          </dt>
                          <dd>
                            <div className="text-lg font-bold text-white">
                              {totalCampaigns}
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="overflow-hidden shadow rounded-lg"
                  style={{
                    backgroundImage: `url(${buCard})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <div className="p-5">
                    <div className="flex items-center">
                      <div className="flex-shrink-0"></div>
                      <div className="ml-5 w-0 flex-1">
                        <dl>
                          <dt className="text-sm font-bold text-white truncate uppercase">
                            Active Business Units
                          </dt>
                          <dd>
                            <div className="text-lg font-bold text-white">
                              {totalBusinessUnits}
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-2 grid grid-cols-12 gap-10 mb-60">
                <div className="h-24 w-full col-span-6">
                  <div className="sm:flex sm:items-center mb-2">
                    <div className="sm:flex-auto">
                      <h2 className="text-2xl leading-6 font-bold text-black mt-16">
                        Weekly Status
                      </h2>
                      <p className="text-gray-400">
                        From {startDate} - {endDate}
                      </p>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                      <svg
                        className="mt-8"
                        width="57"
                        height="57"
                        viewBox="0 0 57 57"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.245117"
                          width="56.6332"
                          height="56.6332"
                          fill="#F9FAFB"
                        />
                        <path
                          d="M21.3369 32.067L24.7319 28.3816C25.1074 27.974 25.7428 27.9495 26.1486 28.3271L28.1282 30.169C28.5121 30.5262 29.1067 30.5262 29.4906 30.169L34.244 25.7462"
                          stroke="#25396F"
                          strokeWidth="1.4"
                          strokeLinecap="round"
                        />
                        <rect
                          x="14.5459"
                          y="16.897"
                          width="25.8141"
                          height="24.019"
                          rx="5"
                          stroke="#25396F"
                          strokeWidth="1.5"
                        />
                      </svg>
                    </div>
                  </div>

                  <BarChart />
                </div>
                <div className="col-span-6">
                  <div className="sm:flex sm:items-center mb-2">
                    <div className="sm:flex-auto">
                      <h2 className="text-2xl leading-6 font-bold text-black mt-16">
                        Top Active Sales Members
                      </h2>
                      <p className="text-gray-400">
                        From {startDate} - {endDate}
                      </p>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                      <svg
                        width="57"
                        height="57"
                        viewBox="0 0 57 57"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.245117"
                          width="56.6332"
                          height="56.6332"
                          fill="#F9FAFB"
                        />
                        <path
                          d="M21.3369 32.067L24.7319 28.3816C25.1074 27.974 25.7428 27.9495 26.1486 28.3271L28.1282 30.169C28.5121 30.5262 29.1067 30.5262 29.4906 30.169L34.244 25.7462"
                          stroke="#25396F"
                          strokeWidth="1.4"
                          strokeLinecap="round"
                        />
                        <rect
                          x="14.5459"
                          y="16.897"
                          width="25.8141"
                          height="24.019"
                          rx="5"
                          stroke="#25396F"
                          strokeWidth="1.5"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="lg:col-span-2">
                    <ul className="space-y-12 sm:grid sm:grid-cols-3 sm:gap-12 sm:space-y-0 lg:gap-x-8">
                      {topActiveSalesMembers.map((member) => (
                        <li>
                          <div className="flex items-center space-x-4 lg:space-x-6">
                            <div className="border-double border-8 border-sky-500">
                              <img
                                className="w-16 h-16 lg:w-20 lg:h-20"
                                alt="member"
                                src={
                                  member.picture
                                    ? `https://redwhale-outbound-staging.nyc3.digitaloceanspaces.com/media/ ${member.picture}`
                                    : `https://ui-avatars.com/api/?name= ${member.full_name} %20 ${member.full_name} &color=7F9CF5&background=EBF4FF`
                                }
                              />
                            </div>
                            <div className="font-medium text-lg leading-6 space-y-1">
                              <p className="text-indigo-600">
                                {member.company_name}
                              </p>
                              <p className="text-black font-bold">
                                {member.full_name}
                              </p>
                            </div>
                          </div>
                        </li>
                      ))}

                      <li>
                        <div className="flex items-center space-x-4 lg:space-x-6">
                          <div className="border-double border-8 border-sky-500">
                            <img
                              alt="member"
                              className="w-16 h-16 lg:w-20 lg:h-20"
                              src={`https://ui-avatars.com/api/?name= WAHAB %20 AWUDU &color=7F9CF5&background=EBF4FF`}
                            />
                          </div>
                          <div className="font-medium text-lg leading-6 space-y-1">
                            <p className="text-indigo-600">New Company</p>
                            <p className="text-black font-bold">Wahab Awudu</p>
                          </div>
                        </div>
                      </li>

                      <li>
                        <div className="flex items-center space-x-4 lg:space-x-6">
                          <div className="border-double border-8 border-sky-500">
                            <img
                              alt="member"
                              className="w-16 h-16 lg:w-20 lg:h-20"
                              src={`https://ui-avatars.com/api/?name=WAHAB%20AWUDU&color=7F9CF5&background=EBF4FF`}
                            />
                          </div>
                          <div className="font-medium text-lg leading-6 space-y-1">
                            <p className="text-indigo-600">New Company</p>
                            <p className="text-black font-bold">Wahab Awudu</p>
                          </div>
                        </div>
                      </li>

                      <li>
                        <div className="flex items-center space-x-4 lg:space-x-6">
                          <div className="border-double border-8 border-sky-500">
                            <img
                              alt="member"
                              className="w-16 h-16 lg:w-20 lg:h-20"
                              src={`https://ui-avatars.com/api/?name= WAHAB %20 AWUDU &color=7F9CF5&background=EBF4FF`}
                            />
                          </div>
                          <div className="font-medium text-lg leading-6 space-y-1">
                            <p className="text-indigo-600">New Company</p>
                            <p className="text-black font-bold">Wahab Awudu</p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="mt-36 mb-28 grid grid-cols-12 gap-5">
                <div className="w-full col-span-8">
                  <div className="px-4 sm:px-6 lg:px-8">
                    <div className="mt-8 flex flex-col">
                      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="sm:flex sm:items-center mb-2">
                          <div className="sm:flex-auto">
                            <h2 className="text-2xl leading-6 font-bold text-black mt-4">
                              Clients
                            </h2>
                          </div>
                          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                            <Link
                              to="#"
                              class="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-bold text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                            >
                              Add New
                            </Link>
                          </div>
                        </div>
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                Name
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Campaigns
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Users
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Status
                              </th>
                              <th
                                scope="col"
                                className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                              >
                                <span className="sr-only">Edit</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white">
                            {allClients.map((client) => (
                              <tr>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {client.name}
                                </td>

                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {client.campaigns_count}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  10
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {client.status === 1 ? (
                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-green-800">
                                      Disabled
                                    </span>
                                  )}
                                </td>
                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                  <Link
                                    to="#"
                                    className="text-indigo-600 hover:text-indigo-900"
                                  >
                                    Edit
                                    <span className="sr-only">
                                      , Lindsay Walton
                                    </span>
                                  </Link>
                                </td>
                              </tr>
                            ))}

                            <tr>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                Redwhale
                              </td>

                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                3
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                10
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {1 ? (
                                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                    Active
                                  </span>
                                ) : (
                                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-green-800">
                                    Disabled
                                  </span>
                                )}
                              </td>
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <Link
                                  to="#"
                                  className="text-indigo-600 hover:text-indigo-900"
                                >
                                  Edit
                                  <span className="sr-only">
                                    , Lindsay Walton
                                  </span>
                                </Link>
                              </td>
                            </tr>

                            <tr>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                Redwhale
                              </td>

                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                3
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                10
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {1 ? (
                                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                    Active
                                  </span>
                                ) : (
                                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-green-800">
                                    Disabled
                                  </span>
                                )}
                              </td>
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <Link
                                  to="#"
                                  className="text-indigo-600 hover:text-indigo-900"
                                >
                                  Edit
                                  <span className="sr-only">
                                    , Lindsay Walton
                                  </span>
                                </Link>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-4">
                  {/* {{-- <h2 className="text-lg leading-6 font-bold text-black mt-4">Clients</h2> --}} */}
                </div>
              </div>
            </>
          ) : null}

          {"role" === "Manager" ? (
            <>
              <div className="mt-2 grid grid-cols-12 gap-5" x-max="1">
                <div
                  className="col-span-3 overflow-hidden shadow rounded-lg"
                  style={{
                    backgroundImage: `url('/images/dashboard/active-users-card.svg')`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <div className="p-5">
                    <div className="flex items-center">
                      <div className="flex-shrink-0"></div>
                      <div className="ml-5 w-0 flex-1">
                        <dl>
                          <dt className="text-sm font-bold text-white truncate uppercase">
                            Active Users
                          </dt>
                          <dd>
                            <div className="text-lg font-bold text-white">
                              {totalUsers}
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-span-3 overflow-hidden shadow rounded-lg"
                  style={{
                    backgroundImage: `url(${clientCard})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <div className="p-5">
                    <div className="flex items-center">
                      <div className="flex-shrink-0"></div>
                      <div className="ml-5 w-0 flex-1">
                        <dl>
                          <dt className="text-sm font-bold text-white truncate uppercase">
                            Total Campaigns
                          </dt>
                          <dd>
                            <div className="text-lg font-bold text-white">
                              {totalCampaigns}
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-span-3 overflow-hidden shadow rounded-lg"
                  style={{
                    backgroundImage: `url('${campCard}')`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <div className="p-5">
                    <div className="flex items-center">
                      <div className="flex-shrink-0"></div>
                      <div className="ml-5 w-0 flex-1">
                        <dl>
                          <dt className="text-sm font-bold text-white truncate uppercase">
                            Active Campaigns
                          </dt>
                          <dd>
                            <div className="text-lg font-bold text-white">
                              {activeCampaigns}
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-span-3 overflow-hidden shadow rounded-lg"
                  style={{
                    backgroundImage: `url(${buCard})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <div className="p-5">
                    <div className="flex items-center">
                      <div className="flex-shrink-0"></div>
                      <div className="ml-5 w-0 flex-1">
                        <dl>
                          <dt className="text-sm font-bold text-white truncate uppercase">
                            Active Business Units
                          </dt>
                          <dd>
                            <div className="text-lg font-bold text-white">
                              {totalBusinessUnits}
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-2 grid grid-cols-12 gap-5">
                <div className="h-full w-full col-span-6">
                  <div className="sm:flex sm:items-center mb-2">
                    <div className="sm:flex-auto">
                      <h2 className="text-2xl leading-6 font-bold text-black mt-16">
                        Weekly Status
                      </h2>
                      <p className="text-gray-400">
                        From {startDate} - {endDate}
                      </p>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                      <svg
                        className="mt-8"
                        width="57"
                        height="57"
                        viewBox="0 0 57 57"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.245117"
                          width="56.6332"
                          height="56.6332"
                          fill="#F9FAFB"
                        />
                        <path
                          d="M21.3369 32.067L24.7319 28.3816C25.1074 27.974 25.7428 27.9495 26.1486 28.3271L28.1282 30.169C28.5121 30.5262 29.1067 30.5262 29.4906 30.169L34.244 25.7462"
                          stroke="#25396F"
                          strokeWidth="1.4"
                          strokeLinecap="round"
                        />
                        <rect
                          x="14.5459"
                          y="16.897"
                          width="25.8141"
                          height="24.019"
                          rx="5"
                          stroke="#25396F"
                          strokeWidth="1.5"
                        />
                      </svg>
                    </div>
                  </div>
                  <div
                    id="chartContainer"
                    style={{ height: "370px", width: "100%" }}
                  ></div>
                  <div className="sm:flex sm:items-center mb-2">
                    <div className="sm:flex-auto">
                      <svg
                        width="38"
                        height="38"
                        viewBox="0 0 38 38"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          y="6.86646e-05"
                          width="37.7555"
                          height="37.7555"
                          rx="7.03704"
                          fill="#F8F8F9"
                        />
                        <g clip-path="url(#clip0_1237_34116)">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.13738 12.8706C9.38176 13.1149 9.77676 13.1149 10.0211 12.8706L11.4543 11.4374L12.8874 12.8706C13.1318 13.1149 13.5268 13.1149 13.7711 12.8706C14.0155 12.6262 14.0155 12.2312 13.7711 11.9868L12.338 10.5537L13.7711 9.12057C14.0155 8.8762 14.0155 8.4812 13.7711 8.23682C13.5268 7.99245 13.1318 7.99245 12.8874 8.23682L11.4543 9.66995L10.0211 8.23682C9.77676 7.99245 9.38176 7.99245 9.13738 8.23682C8.89301 8.4812 8.89301 8.8762 9.13738 9.12057L10.5705 10.5537L9.13738 11.9868C8.89301 12.2312 8.89301 12.6262 9.13738 12.8706Z"
                            fill="#25396F"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M25.0211 21.9868C24.7768 21.7424 24.3818 21.7424 24.1374 21.9868L22.7043 23.4199L21.2711 21.9868C21.0268 21.7424 20.6318 21.7424 20.3874 21.9868C20.143 22.2312 20.143 22.6262 20.3874 22.8706L21.8205 24.3037L20.3874 25.7368C20.143 25.9812 20.143 26.3762 20.3874 26.6206C20.6318 26.8649 21.0268 26.8649 21.2711 26.6206L22.7043 25.1874L24.1374 26.6206C24.3818 26.8649 24.7768 26.8649 25.0211 26.6206C25.2655 26.3762 25.2655 25.9812 25.0211 25.7368L23.588 24.3037L25.0211 22.8706C25.2655 22.6262 25.2655 22.2312 25.0211 21.9868Z"
                            fill="#25396F"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M21.271 10.9956L22.0791 10.1875V16.1787C22.0791 16.5231 21.7991 16.8037 21.4541 16.8037H12.7041C11.6704 16.8037 10.8291 17.645 10.8291 18.6787V19.9287C10.8291 20.2737 11.1091 20.5537 11.4541 20.5537C11.7991 20.5537 12.0791 20.2737 12.0791 19.9287V18.6787C12.0791 18.3343 12.3597 18.0537 12.7041 18.0537H21.4541C22.4879 18.0537 23.3291 17.2125 23.3291 16.1787V10.1875L24.1372 10.9956C24.3816 11.24 24.7766 11.24 25.021 10.9956C25.2654 10.7512 25.2654 10.3562 25.021 10.1118L23.1466 8.23745C22.9054 7.9962 22.5066 7.99183 22.2616 8.23745L20.3872 10.1118C20.1429 10.3562 20.1429 10.7512 20.3872 10.9956C20.6316 11.24 21.0266 11.24 21.271 10.9956Z"
                            fill="#25396F"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M11.4541 25.5537C10.7647 25.5537 10.2041 24.9931 10.2041 24.3037C10.2041 23.6143 10.7647 23.0537 11.4541 23.0537C12.1435 23.0537 12.7041 23.6143 12.7041 24.3037C12.7041 24.9931 12.1435 25.5537 11.4541 25.5537ZM11.4541 21.8037C10.0754 21.8037 8.9541 22.9249 8.9541 24.3037C8.9541 25.6824 10.0754 26.8037 11.4541 26.8037C12.8329 26.8037 13.9541 25.6824 13.9541 24.3037C13.9541 22.9249 12.8329 21.8037 11.4541 21.8037Z"
                            fill="#25396F"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1237_34116">
                            <rect
                              width="23.5972"
                              height="23.5972"
                              fill="white"
                              transform="translate(7.0791 7.4287)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                      <svg
                        width="38"
                        height="38"
                        viewBox="0 0 38 38"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.237305"
                          width="37.7555"
                          height="37.7555"
                          rx="7.03704"
                          fill="#F8F8F9"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M28.7012 18.4899H19.8523V9.64093H27.9638C28.3709 9.64093 28.7012 9.97203 28.7012 10.3783V18.4899ZM28.7012 28.0762C28.7012 28.4825 28.3709 28.8136 27.9638 28.8136H19.8523V19.9647H28.7012V28.0762ZM18.3775 18.4899H9.52853V10.3783C9.52853 9.97203 9.85889 9.64093 10.2659 9.64093H18.3775V18.4899ZM18.3775 28.8136H10.2659C9.85889 28.8136 9.52853 28.4825 9.52853 28.0762V19.9647H18.3775V28.8136ZM27.9638 8.16611H10.2659C9.04627 8.16611 8.05371 9.15866 8.05371 10.3783V28.0762C8.05371 29.2959 9.04627 30.2884 10.2659 30.2884H27.9638C29.1835 30.2884 30.1761 29.2959 30.1761 28.0762V10.3783C30.1761 9.15866 29.1835 8.16611 27.9638 8.16611Z"
                          fill="#364F6B"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M25.7515 13.328H22.8019C22.3941 13.328 22.0645 13.6583 22.0645 14.0654C22.0645 14.4724 22.3941 14.8028 22.8019 14.8028H25.7515C26.1593 14.8028 26.4889 14.4724 26.4889 14.0654C26.4889 13.6583 26.1593 13.328 25.7515 13.328Z"
                          fill="#364F6B"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M22.8019 26.6014H25.7515C26.1593 26.6014 26.4889 26.271 26.4889 25.864C26.4889 25.4569 26.1593 25.1266 25.7515 25.1266H22.8019C22.3941 25.1266 22.0645 25.4569 22.0645 25.864C22.0645 26.271 22.3941 26.6014 22.8019 26.6014Z"
                          fill="#364F6B"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M22.8019 23.6517H25.7515C26.1593 23.6517 26.4889 23.3214 26.4889 22.9143C26.4889 22.5073 26.1593 22.1769 25.7515 22.1769H22.8019C22.3941 22.1769 22.0645 22.5073 22.0645 22.9143C22.0645 23.3214 22.3941 23.6517 22.8019 23.6517Z"
                          fill="#364F6B"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12.4786 14.8028H13.216V15.5402C13.216 15.9473 13.5457 16.2776 13.9534 16.2776C14.3612 16.2776 14.6909 15.9473 14.6909 15.5402V14.8028H15.4283C15.8361 14.8028 16.1657 14.4724 16.1657 14.0654C16.1657 13.6583 15.8361 13.328 15.4283 13.328H14.6909V12.5906C14.6909 12.1835 14.3612 11.8532 13.9534 11.8532C13.5457 11.8532 13.216 12.1835 13.216 12.5906V13.328H12.4786C12.0708 13.328 11.7412 13.6583 11.7412 14.0654C11.7412 14.4724 12.0708 14.8028 12.4786 14.8028Z"
                          fill="#364F6B"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11.9565 26.3853C12.2448 26.6737 12.7109 26.6737 12.9992 26.3853L13.9527 25.4319L14.9061 26.3853C15.1945 26.6737 15.6605 26.6737 15.9488 26.3853C16.2372 26.097 16.2372 25.631 15.9488 25.3426L14.9954 24.3892L15.9488 23.4357C16.2372 23.1474 16.2372 22.6813 15.9488 22.393C15.6605 22.1047 15.1945 22.1047 14.9061 22.393L13.9527 23.3465L12.9992 22.393C12.7109 22.1047 12.2448 22.1047 11.9565 22.393C11.6682 22.6813 11.6682 23.1474 11.9565 23.4357L12.91 24.3892L11.9565 25.3426C11.6682 25.631 11.6682 26.097 11.9565 26.3853Z"
                          fill="#364F6B"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="col-span-6">
                  <div className="sm:flex sm:items-center mb-2">
                    <div className="sm:flex-auto">
                      <h2 className="text-2xl leading-6 font-bold text-black mt-16">
                        Top Active Sales Members
                      </h2>
                      <p className="text-gray-400">
                        From {startDate} - {endDate}
                      </p>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                      {/* // {{-- <img className="w-16 h-16 lg:w-20 lg:h-20" src="member->picture != null ? 'https://redwhale-outbound-staging.nyc3.digitaloceanspaces.com/media/' . $member->picture : 'https://ui-avatars.com/api/?name=' . $member->full_name . '%20' . $member->full_name . '&color=7F9CF5&background=EBF4FF' }}"> --}} */}
                    </div>
                  </div>

                  <div className="lg:col-span-2">
                    <ul className="space-y-12 sm:grid sm:grid-cols-3 sm:gap-12 sm:space-y-0 lg:gap-x-8"></ul>
                  </div>
                </div>
              </div>
              <div className="mt-8 grid grid-cols-12 gap-5">
                <div className="w-full col-span-12">
                  <div className="px-4 sm:px-6 lg:px-8">
                    <div className="mt-8 flex flex-col">
                      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="sm:flex sm:items-center mb-2">
                          <div className="sm:flex-auto">
                            <h2 className="text-2xl leading-6 font-bold text-black mt-4">
                              Campaigns
                            </h2>
                          </div>
                          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
                        </div>
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                Name
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Business Unit
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Source
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Status
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Sales Members
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white">
                            {campaigns.map((campaign) => (
                              <tr>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {campaign.name}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {campaign.department.name}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {campaign.primary_source}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {campaign.active ? (
                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-green-800">
                                      Disabled
                                    </span>
                                  )}
                                </td>
                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                  <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                                    <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                                      <div className="flex overflow-hidden -space-x-1">
                                        <img
                                          className="inline-block h-6 w-6 rounded-full ring-2 ring-white"
                                          src="https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                          alt="Dries Vincent"
                                        />

                                        <img
                                          className="inline-block h-6 w-6 rounded-full ring-2 ring-white"
                                          src="https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                          alt="Lindsay Walton"
                                        />

                                        <img
                                          className="inline-block h-6 w-6 rounded-full ring-2 ring-white"
                                          src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                          alt="Courtney Henry"
                                        />

                                        <img
                                          className="inline-block h-6 w-6 rounded-full ring-2 ring-white"
                                          src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                          alt="Tom Cook"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-4"></div>
              </div>
            </>
          ) : null}

          {["Admin", "Manager"].includes("role") ? (
            <>
              <div
                className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4"
                x-max="1"
              >
                <div
                  className=" overflow-hidden shadow rounded-lg"
                  style={{
                    backgroundImage: `url('/images/dashboard/active-users-card.svg')`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <div className="p-5">
                    <div className="flex items-center">
                      <div className="flex-shrink-0"></div>
                      <div className="ml-5 w-0 flex-1">
                        <dl>
                          <dt className="text-sm font-bold text-white truncate uppercase">
                            Total Leads
                          </dt>
                          <dd>
                            <div className="text-lg font-bold text-white">
                              {totalLeads}
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="overflow-hidden shadow rounded-lg"
                  style={{
                    backgroundImage: `url(${clientCard})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <div className="p-5">
                    <div className="flex items-center">
                      <div className="flex-shrink-0"></div>
                      <div className="ml-5 w-0 flex-1">
                        <dl>
                          <dt className="text-sm font-bold text-white truncate uppercase">
                            Total Messages
                          </dt>
                          <dd>
                            <div className="text-lg font-bold text-white">
                              {totalMessages}
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="overflow-hidden shadow rounded-lg"
                  style={{
                    backgroundImage: `url('${campCard}')`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <div className="p-5">
                    <div className="flex items-center">
                      <div className="flex-shrink-0"></div>
                      <div className="ml-5 w-0 flex-1">
                        <dl>
                          <dt className="text-sm font-bold text-white truncate uppercase">
                            Active Campaigns
                          </dt>
                          <dd>
                            <div className="text-lg font-bold text-white">
                              {activeCampaigns}
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="overflow-hidden shadow rounded-lg"
                  style={{
                    backgroundImage: `url(${buCard})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <div className="p-5">
                    <div className="flex items-center">
                      <div className="flex-shrink-0"></div>
                      <div className="ml-5 w-0 flex-1">
                        <dl>
                          <dt className="text-sm font-bold text-white truncate uppercase">
                            Active Business Units
                          </dt>
                          <dd>
                            <div className="text-lg font-bold text-white">
                              {totalBusinessUnits}
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-2 grid grid-cols-12 gap-5">
                <div className="h-24 w-full col-span-6">
                  <div className="sm:flex sm:items-center mb-2">
                    <div className="sm:flex-auto">
                      <h2 className="text-2xl leading-6 font-bold text-black mt-16">
                        Weekly Status
                      </h2>
                      <p className="text-gray-400">
                        From {startDate} - {endDate}
                      </p>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                      <svg
                        className="mt-8"
                        width="57"
                        height="57"
                        viewBox="0 0 57 57"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.245117"
                          width="56.6332"
                          height="56.6332"
                          fill="#F9FAFB"
                        />
                        <path
                          d="M21.3369 32.067L24.7319 28.3816C25.1074 27.974 25.7428 27.9495 26.1486 28.3271L28.1282 30.169C28.5121 30.5262 29.1067 30.5262 29.4906 30.169L34.244 25.7462"
                          stroke="#25396F"
                          strokeWidth="1.4"
                          strokeLinecap="round"
                        />
                        <rect
                          x="14.5459"
                          y="16.897"
                          width="25.8141"
                          height="24.019"
                          rx="5"
                          stroke="#25396F"
                          strokeWidth="1.5"
                        />
                      </svg>
                    </div>
                  </div>
                  <div
                    id="chartContainer"
                    style={{ height: "370px", width: "100%" }}
                  ></div>
                  <div className="sm:flex sm:items-center mb-2">
                    <div className="sm:flex-auto">
                      <svg
                        width="38"
                        height="38"
                        viewBox="0 0 38 38"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          y="6.86646e-05"
                          width="37.7555"
                          height="37.7555"
                          rx="7.03704"
                          fill="#F8F8F9"
                        />
                        <g clip-path="url(#clip0_1237_34116)">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.13738 12.8706C9.38176 13.1149 9.77676 13.1149 10.0211 12.8706L11.4543 11.4374L12.8874 12.8706C13.1318 13.1149 13.5268 13.1149 13.7711 12.8706C14.0155 12.6262 14.0155 12.2312 13.7711 11.9868L12.338 10.5537L13.7711 9.12057C14.0155 8.8762 14.0155 8.4812 13.7711 8.23682C13.5268 7.99245 13.1318 7.99245 12.8874 8.23682L11.4543 9.66995L10.0211 8.23682C9.77676 7.99245 9.38176 7.99245 9.13738 8.23682C8.89301 8.4812 8.89301 8.8762 9.13738 9.12057L10.5705 10.5537L9.13738 11.9868C8.89301 12.2312 8.89301 12.6262 9.13738 12.8706Z"
                            fill="#25396F"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M25.0211 21.9868C24.7768 21.7424 24.3818 21.7424 24.1374 21.9868L22.7043 23.4199L21.2711 21.9868C21.0268 21.7424 20.6318 21.7424 20.3874 21.9868C20.143 22.2312 20.143 22.6262 20.3874 22.8706L21.8205 24.3037L20.3874 25.7368C20.143 25.9812 20.143 26.3762 20.3874 26.6206C20.6318 26.8649 21.0268 26.8649 21.2711 26.6206L22.7043 25.1874L24.1374 26.6206C24.3818 26.8649 24.7768 26.8649 25.0211 26.6206C25.2655 26.3762 25.2655 25.9812 25.0211 25.7368L23.588 24.3037L25.0211 22.8706C25.2655 22.6262 25.2655 22.2312 25.0211 21.9868Z"
                            fill="#25396F"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M21.271 10.9956L22.0791 10.1875V16.1787C22.0791 16.5231 21.7991 16.8037 21.4541 16.8037H12.7041C11.6704 16.8037 10.8291 17.645 10.8291 18.6787V19.9287C10.8291 20.2737 11.1091 20.5537 11.4541 20.5537C11.7991 20.5537 12.0791 20.2737 12.0791 19.9287V18.6787C12.0791 18.3343 12.3597 18.0537 12.7041 18.0537H21.4541C22.4879 18.0537 23.3291 17.2125 23.3291 16.1787V10.1875L24.1372 10.9956C24.3816 11.24 24.7766 11.24 25.021 10.9956C25.2654 10.7512 25.2654 10.3562 25.021 10.1118L23.1466 8.23745C22.9054 7.9962 22.5066 7.99183 22.2616 8.23745L20.3872 10.1118C20.1429 10.3562 20.1429 10.7512 20.3872 10.9956C20.6316 11.24 21.0266 11.24 21.271 10.9956Z"
                            fill="#25396F"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M11.4541 25.5537C10.7647 25.5537 10.2041 24.9931 10.2041 24.3037C10.2041 23.6143 10.7647 23.0537 11.4541 23.0537C12.1435 23.0537 12.7041 23.6143 12.7041 24.3037C12.7041 24.9931 12.1435 25.5537 11.4541 25.5537ZM11.4541 21.8037C10.0754 21.8037 8.9541 22.9249 8.9541 24.3037C8.9541 25.6824 10.0754 26.8037 11.4541 26.8037C12.8329 26.8037 13.9541 25.6824 13.9541 24.3037C13.9541 22.9249 12.8329 21.8037 11.4541 21.8037Z"
                            fill="#25396F"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1237_34116">
                            <rect
                              width="23.5972"
                              height="23.5972"
                              fill="white"
                              transform="translate(7.0791 7.4287)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                      <svg
                        width="38"
                        height="38"
                        viewBox="0 0 38 38"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.237305"
                          width="37.7555"
                          height="37.7555"
                          rx="7.03704"
                          fill="#F8F8F9"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M28.7012 18.4899H19.8523V9.64093H27.9638C28.3709 9.64093 28.7012 9.97203 28.7012 10.3783V18.4899ZM28.7012 28.0762C28.7012 28.4825 28.3709 28.8136 27.9638 28.8136H19.8523V19.9647H28.7012V28.0762ZM18.3775 18.4899H9.52853V10.3783C9.52853 9.97203 9.85889 9.64093 10.2659 9.64093H18.3775V18.4899ZM18.3775 28.8136H10.2659C9.85889 28.8136 9.52853 28.4825 9.52853 28.0762V19.9647H18.3775V28.8136ZM27.9638 8.16611H10.2659C9.04627 8.16611 8.05371 9.15866 8.05371 10.3783V28.0762C8.05371 29.2959 9.04627 30.2884 10.2659 30.2884H27.9638C29.1835 30.2884 30.1761 29.2959 30.1761 28.0762V10.3783C30.1761 9.15866 29.1835 8.16611 27.9638 8.16611Z"
                          fill="#364F6B"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M25.7515 13.328H22.8019C22.3941 13.328 22.0645 13.6583 22.0645 14.0654C22.0645 14.4724 22.3941 14.8028 22.8019 14.8028H25.7515C26.1593 14.8028 26.4889 14.4724 26.4889 14.0654C26.4889 13.6583 26.1593 13.328 25.7515 13.328Z"
                          fill="#364F6B"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M22.8019 26.6014H25.7515C26.1593 26.6014 26.4889 26.271 26.4889 25.864C26.4889 25.4569 26.1593 25.1266 25.7515 25.1266H22.8019C22.3941 25.1266 22.0645 25.4569 22.0645 25.864C22.0645 26.271 22.3941 26.6014 22.8019 26.6014Z"
                          fill="#364F6B"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M22.8019 23.6517H25.7515C26.1593 23.6517 26.4889 23.3214 26.4889 22.9143C26.4889 22.5073 26.1593 22.1769 25.7515 22.1769H22.8019C22.3941 22.1769 22.0645 22.5073 22.0645 22.9143C22.0645 23.3214 22.3941 23.6517 22.8019 23.6517Z"
                          fill="#364F6B"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12.4786 14.8028H13.216V15.5402C13.216 15.9473 13.5457 16.2776 13.9534 16.2776C14.3612 16.2776 14.6909 15.9473 14.6909 15.5402V14.8028H15.4283C15.8361 14.8028 16.1657 14.4724 16.1657 14.0654C16.1657 13.6583 15.8361 13.328 15.4283 13.328H14.6909V12.5906C14.6909 12.1835 14.3612 11.8532 13.9534 11.8532C13.5457 11.8532 13.216 12.1835 13.216 12.5906V13.328H12.4786C12.0708 13.328 11.7412 13.6583 11.7412 14.0654C11.7412 14.4724 12.0708 14.8028 12.4786 14.8028Z"
                          fill="#364F6B"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11.9565 26.3853C12.2448 26.6737 12.7109 26.6737 12.9992 26.3853L13.9527 25.4319L14.9061 26.3853C15.1945 26.6737 15.6605 26.6737 15.9488 26.3853C16.2372 26.097 16.2372 25.631 15.9488 25.3426L14.9954 24.3892L15.9488 23.4357C16.2372 23.1474 16.2372 22.6813 15.9488 22.393C15.6605 22.1047 15.1945 22.1047 14.9061 22.393L13.9527 23.3465L12.9992 22.393C12.7109 22.1047 12.2448 22.1047 11.9565 22.393C11.6682 22.6813 11.6682 23.1474 11.9565 23.4357L12.91 24.3892L11.9565 25.3426C11.6682 25.631 11.6682 26.097 11.9565 26.3853Z"
                          fill="#364F6B"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="col-span-6">
                  <div className="sm:flex sm:items-center mb-2">
                    <div className="sm:flex-auto">
                      <h2 className="text-2xl leading-6 font-bold text-black mt-16">
                        Top Active Leads
                      </h2>
                      <p className="text-gray-400">
                        From {startDate} - {endDate}
                      </p>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                      <svg
                        width="57"
                        height="57"
                        viewBox="0 0 57 57"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.245117"
                          width="56.6332"
                          height="56.6332"
                          fill="#F9FAFB"
                        />
                        <path
                          d="M21.3369 32.067L24.7319 28.3816C25.1074 27.974 25.7428 27.9495 26.1486 28.3271L28.1282 30.169C28.5121 30.5262 29.1067 30.5262 29.4906 30.169L34.244 25.7462"
                          stroke="#25396F"
                          strokeWidth="1.4"
                          strokeLinecap="round"
                        />
                        <rect
                          x="14.5459"
                          y="16.897"
                          width="25.8141"
                          height="24.019"
                          rx="5"
                          stroke="#25396F"
                          strokeWidth="1.5"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="lg:col-span-2">
                    <ul className="space-y-12 sm:grid sm:grid-cols-3 sm:gap-12 sm:space-y-0 lg:gap-x-8">
                      {topActiveLeads.map((lead) => (
                        <li>
                          <div className="flex items-center space-x-4 lg:space-x-6">
                            <div className="border-double border-8 border-sky-500">
                              <img
                                className="w-16 h-16 lg:w-20 lg:h-20"
                                src="{{ $lead->image != null ? 'https://redwhale-outbound-staging.nyc3.digitaloceanspaces.com/media/' . $lead->image : 'https://ui-avatars.com/api/?name=' . $lead->first_name . '%20' . $lead->last_name . '&color=7F9CF5&background=EBF4FF' }}"
                                alt="{{ $lead->first_name }}"
                              />
                            </div>
                            <div className="font-medium text-lg leading-6 space-y-1">
                              <p className="truncate w-24">
                                <span className="text-black font-bold text-sm">
                                  {lead.first_name}
                                  {lead.last_name}
                                </span>
                                <br />
                                <span className="text-gray-600 text-xs">
                                  {lead.company_name}
                                </span>
                              </p>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="mt-8 grid grid-cols-12 gap-5">
                <div className="w-full col-span-12">
                  <div className="px-4 sm:px-6 lg:px-8">
                    <div className="mt-8 flex flex-col">
                      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="sm:flex sm:items-center mb-2">
                          <div className="sm:flex-auto">
                            <h2 className="text-2xl leading-6 font-bold text-black mt-4">
                              Campaigns
                            </h2>
                          </div>
                          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
                        </div>
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 uppercase"
                              >
                                Name
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
                              >
                                Business Unit
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
                              >
                                Source
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
                              >
                                Status
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white">
                            {campaigns.map((campaign) => (
                              <tr>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {campaign.name}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {campaign.business_unit_name}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {campaign.primary_source}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {campaign.active ? (
                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-green-800">
                                      Disabled
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-4"></div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </PageLayout>
  );
};

export default Dashboard;
