import React, { useEffect, useState } from "react";
import PageLayout from "../../components/Layouts/PageLayout";

const Create = (props) => {
  const pages = [
    { name: "Dashboard", to: "/", current: false },
    { name: "Texau Campaigns", to: "/texau", current: false },
    { name: "Add New", to: "#", current: true },
  ];

  const followUpFields = {
    id: "",
    follow_up_message: "",
    days: 0,
    hours: 0,
  };

  // eslint-disable-next-line
  const [clients, setClients] = useState([]);
  // eslint-disable-next-line
  const [reps, setReps] = useState([]);
  // eslint-disable-next-line
  const [camps, setCamps] = useState([]);
  const [showFollowUps, setShowFollowUps] = useState(false);
  // eslint-disable-next-line
  const [currentFollowUps, setCurrentFollowUps] = useState(1);
  // eslint-disable-next-line
  const [state, setState] = useState({});
  const [followUps, setFollowUps] = useState([]);
  const [errorMessage, setErrorMessage] = useState({});

  useEffect(() => {
    if (!followUps.length) {
      setFollowUps([...followUps, followUpFields]);
    }
    // eslint-disable-next-line
  }, []);

  const handleFollowUpPlaceholder = () => {};

  const removeFollowUp = (index) => {
    if (followUps.length > 1) {
      setFollowUps(followUps.filter((f, fId) => index !== fId));
    } else {
      console.log("CANT REMOVE");
    }
  };

  const addFollowUp = () => {
    setFollowUps([...followUps, followUpFields]);
  };

  // eslint-disable-next-line
  const handleSelectUpdate = (stateName) => (obj) => {
    if (obj) {
      setState({ [stateName]: obj.value });
    } else {
      setState({ [stateName]: "" });
    }

    // remove form field errors
    if (errorMessage[stateName]) {
      setErrorMessage({
        ...errorMessage,
        [stateName]: null,
        nonFieldErrors: null,
      });
    }
  };

  // eslint-disable-next-line
  const handleFieldUpdate = (stateName) => (event) => {
    setState({ [stateName]: event.target.value });

    // remove form field errors
    if (errorMessage[stateName]) {
      setErrorMessage({
        ...errorMessage,
        [stateName]: null,
        nonFieldErrors: null,
      });
    }
  };

  // eslint-disable-next-line
  const handleMessageFieldUpdate = (id) => (event) => {
    const updatedMessages = followUps.map((follow, fId) => {
      // maintain other messages.
      if (id !== fId) return follow;
      // update this messages.
      return { ...follow, [event.target.name]: event.target.value };
    });
    setFollowUps(updatedMessages);

    // update messages errors
    // if (this.state.errorMessage.houses) {
    //   const updatedHousesErrors = this.state.errorMessage.houses.map((h) => {
    //     // maintain other house errors
    //     if (id !== h.houseId) return h;

    //     return { ...h, [event.target.name]: null };
    //   });

    //   this.setState((prevState) => ({
    //     errorMessage: {
    //       ...prevState.errorMessage,
    //       houses: updatedHousesErrors,
    //       nonFieldErrors: null,
    //     },
    //   }));
    // }
    // end update house errors
  };

  return (
    <PageLayout pages={pages}>
      <div className="container py-5 px-5 mx-auto">
        <form
          onSubmit={() => {}}
          className="shadow overflow-hidden sm:rounded-md mt-6 px-10 py-10 bg-white"
        >
          <div className="grid grid-cols-3 gap-10">
            <div>
              <label
                htmlFor="client_name"
                className="block text-sm font-medium text-gray-700"
              >
                Client
              </label>
              <select
                id="client_name"
                name="client_name"
                required
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="" disabled>
                  ---- Choose ----
                </option>
                {clients.map((client) => (
                  <option value={client.id}>{client.name}</option>
                ))}
              </select>
            </div>

            <div>
              <label
                htmlFor="sales_rep"
                className="block text-sm font-medium text-gray-700"
              >
                Sales Person
              </label>
              <select
                id="sales_rep"
                name="sales_rep"
                required
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="" disabled>
                  ---- Choose ----
                </option>
                {reps.map((rep) => (
                  <option value={rep.id}>{rep.fullName}</option>
                ))}
              </select>
            </div>

            <div>
              <label
                htmlFor="campaign"
                className="block text-sm font-medium text-gray-700"
              >
                Campaign
              </label>
              <select
                id="campaign"
                name="campaign"
                required
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="" disabled>
                  ---- Choose ----
                </option>
                {camps.map((camp) => (
                  <option value={camp.id}>{camp.campaign.name}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="grid grid-cols-1 mt-5">
            <div className="mt-5">
              <label
                htmlFor="linkedin_url"
                className="block text-sm font-medium text-gray-700"
              >
                Linkedin Url For People we are targeting in this campaign
              </label>
              <input
                type="text"
                name="linkedin_url"
                id="linkedin_url"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                required
              />
            </div>

            <div className="mt-5">
              <label
                htmlFor="welcome_message"
                className="block text-sm font-medium text-gray-700"
              >
                Connection Message
              </label>
              <textarea
                name="welcome_message"
                id="welcome_message"
                required
                cols="50"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              ></textarea>
            </div>

            <div className="flex justify-end mt-2">
              {/* {{-- <label for="connection_placeholder">Choose a membership plan:</label> --}} */}
              <select name="connection_placeholder" id="connection_placeholder">
                <option value="">---Insert Placeholder---</option>
                <option value="{{firstName}}">First Name</option>
                <option value="{{lastName}}">Last Name</option>
                <option value="{{headline}}">Headline/Title</option>
                <option value="{{location}}">Location</option>
              </select>
            </div>

            {!showFollowUps ? (
              <div className="mt-5" id="show_follow_up_section">
                <label
                  htmlFor="show_follow_up"
                  className="block text-sm font-medium text-gray-700"
                >
                  <input
                    type="checkbox"
                    name="show_follow_up"
                    id="show_follow_up"
                    onChange={() => {
                      setShowFollowUps(!showFollowUps);
                    }}
                    value={showFollowUps}
                    className="mr-2"
                  />{" "}
                  Add Follow Up Messages
                </label>
              </div>
            ) : null}

            {/* {{-- FOLLOW UP MESSAGES SECTION --}} */}
            <div className="follow-up-messages mt-5" id="follow-up-messages">
              {showFollowUps
                ? followUps.map((follow, i) => (
                    <div className="mt-5" key={i}>
                      <label
                        htmlFor={`follow_up_message_${i + 1}`}
                        className="block text-sm font-medium text-gray-700"
                      >
                        Follow Up Message #{i + 1}
                      </label>
                      <div>
                        <input
                          type="text"
                          value={follow.days}
                          onChange={() => {}}
                          required
                          name={`days_${i + 1}`}
                          className="mt-1 inline w-12 sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 shadow-sm border-gray-300 rounded-md"
                        />{" "}
                        days and
                        <input
                          type="text"
                          value={follow.hours}
                          onChange={() => {}}
                          required
                          name={`hours_${i + 1}`}
                          className="mt-1 inline w-12 sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 shadow-sm border-gray-300 rounded-md"
                        />{" "}
                        hours after connecting
                      </div>
                      <textarea
                        name={`follow_up_message_${i + 1}`}
                        value={follow.follow_up_message}
                        onChange={() => {}}
                        required
                        className="mt-3 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      ></textarea>
                      <div className="flex justify-end mt-2">
                        <select
                          onChange={() => handleFollowUpPlaceholder(i)}
                          name={`follow_up_message_${i + 1}_placeholder`}
                        >
                          <option value="">---Insert Placeholder---</option>
                          <option value="{{firstName}}">First Name</option>
                          <option value="{{lastName}}">Last Name</option>
                          <option value="{{headline}}">Headline/Title</option>
                          <option value="{{location}}">Location</option>
                        </select>
                      </div>

                      <div className="mt-3 text-right">
                        <button
                          type="button"
                          onClick={() => removeFollowUp(i)}
                          className="inline-flex justify-center py-2 px-3 mr-3 border border-gray-500 shadow-sm text-sm font-medium rounded-md text-gray-500 bg-white hover:bg-gray-200 focus:outline-none"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5 10a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                        <button
                          type="button"
                          onClick={() => addFollowUp()}
                          className="inline-flex justify-center py-2 px-3 border border-gray-500 shadow-sm text-sm font-medium rounded-md text-gray-500 bg-white hover:bg-gray-200 focus:outline-none"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  ))
                : null}
            </div>
            {/* {{-- END FOLLOW UP MESSAGES SECTION --}} */}

            <div className="grid grid-cols-2 mt-5">
              <div className="mr-5">
                <label
                  htmlFor="profile_limit"
                  className="block text-sm font-medium text-gray-700"
                >
                  Daily Profile Visit Limit
                </label>
                <input
                  type="text"
                  name="profile_limit"
                  id="profile_limit"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  required
                />
              </div>

              <div className="">
                <label
                  htmlFor="connection_limit"
                  className="block text-sm font-medium text-gray-700"
                >
                  Daily Connection Limit
                </label>
                <input
                  type="text"
                  name="connection_limit"
                  id="connection_limit"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  required
                />
              </div>
            </div>
          </div>

          <div className="py-3 text-right mt-4 flex flex-row items-center gap-5 justify-end">
            <button
              type="button"
              className="inline-flex justify-center py-2 px-4 border border-gray-500 shadow-sm text-sm font-medium rounded-md text-gray-500 bg-white hover:bg-gray-200 focus:outline-none"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save Campaign
            </button>
          </div>
        </form>
      </div>
    </PageLayout>
  );
};

export default Create;
