import { constants } from "../../app-constants";

let savedUser = localStorage.getItem("web.redwhaleUser");
if (!savedUser) savedUser = "{}";
const initialState = JSON.parse(savedUser);

export const authUser = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_USER:
      localStorage.setItem("web.redwhaleUser", JSON.stringify(action.payload));
      return action.payload;
    case constants.UNSET_USER:
      localStorage.removeItem("web.redwhaleUser");
      return {};
    default:
      return state;
  }
};

const initialUsers = [];

export const users = (state = initialUsers, action) => {
  switch (action.type) {
    case constants.SET_USERS:
      return action.payload;
    case constants.UNSET_USERS:
      return [];
    default:
      return state;
  }
};
