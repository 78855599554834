import { constants } from "../../app-constants";

export const setCampaigns = (businesses) => ({
  type: constants.SET_CAMPAIGNS,
  payload: businesses,
});

export const unsetCampaigns = () => ({
  type: constants.UNSET_CAMPAIGNS,
});
