import { constants } from "../../app-constants";

export const setUser = (user) => ({
  type: constants.SET_USER,
  payload: user,
});

export const unsetUser = () => ({
  type: constants.UNSET_USER,
});

export const setUsers = (users) => ({
  type: constants.SET_USERS,
  payload: users,
});

export const unsetUsers = () => ({
  type: constants.UNSET_USERS,
});
