import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import PrivateRoute from "../hocs/PrivateRoute";
import DashboardLayout from "../components/Layouts";
import Dashboard from "./Dashboard";
import UserRoutes from "./users";
import ClientRoutes from "./clients";
import BusinessUnitRoutes from "./businessunits";
import TexauRoutes from "./texau";
import PageNotFound from "./PageNotFound";

const DashboardRoutes = (props) => {
  return (
    <DashboardLayout>
      <Switch>
        <PrivateRoute exact path={props.match.path} component={Dashboard} />
        <PrivateRoute
          path={`${props.match.path}texau`}
          component={TexauRoutes}
        />
        <PrivateRoute
          path={`${props.match.path}users`}
          component={UserRoutes}
        />
        <PrivateRoute
          path={`${props.match.path}clients`}
          component={ClientRoutes}
        />
        <PrivateRoute
          path={`${props.match.path}business-units`}
          component={BusinessUnitRoutes}
        />
        <Route component={PageNotFound} />
      </Switch>
    </DashboardLayout>
  );
};

DashboardRoutes.propTypes = {
  match: PropTypes.object,
};

export default withRouter(DashboardRoutes);
