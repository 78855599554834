import React from "react";
import { HomeIcon } from "@heroicons/react/solid";
import propType from "prop-types";
import { Link } from "react-router-dom";

const PageLayout = ({ children, pages }) => {
  // pages format
  // const pages = [
  //   { name: "Projects", to: "#", current: false },
  //   { name: "Project Nero", to: "#", current: true },
  // ];

  return (
    <React.Fragment>
      <nav className="flex" aria-label="Breadcrumb">
        <ol className="bg-white rounded-md shadow px-6 flex space-x-4">
          <li className="flex">
            <div className="flex items-center">
              <Link to="/" className="text-gray-400 hover:text-gray-500">
                <HomeIcon
                  className="flex-shrink-0 h-5 w-5"
                  aria-hidden="true"
                />
                <span className="sr-only">Home</span>
              </Link>
            </div>
          </li>
          {pages.map((page) => (
            <li key={page.name} className="flex">
              <div className="flex items-center">
                <svg
                  className="flex-shrink-0 w-6 h-full text-gray-200"
                  viewBox="0 0 24 44"
                  preserveAspectRatio="none"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                >
                  <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <Link
                  to={page.to}
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  aria-current={page.current ? "page" : undefined}
                >
                  {page.name}
                </Link>
              </div>
            </li>
          ))}
        </ol>
      </nav>

      {children}
    </React.Fragment>
  );
};

PageLayout.propTypes = {
  pages: propType.array.isRequired,
};

export default PageLayout;
