import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
// import { Input } from "antd";
import React, { useEffect, useState } from "react";
import PageLayout from "../../components/Layouts/PageLayout";
import {
  clientsUrl,
  errorNotification,
  successNotification,
  getHeaders,
  usersUrl,
  businessUnitsUrl,
} from "../../config";

const Create = (props) => {
  const pages = [
    { name: "Dashboard", to: "/", current: false },
    { name: "Business Units", to: "/business-units", current: false },
    { name: "Add New", to: "#", current: true },
  ];

  const [clients, setClients] = useState([]);
  const [users, setUsers] = useState([]);

  const [state, setState] = useState({
    name: "",
    client_id: "",
    user_id: "",
    slack_channel_id: "",
  });
  const [errorMessage, setErrorMessage] = useState({});
  const [clientLoading, setClientLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const getClients = () => {
    setClientLoading(true);
    axios
      .get(clientsUrl, { headers: getHeaders() })
      .then((res) => {
        setClients(res.data.data);
        setClientLoading(false);
      })
      .catch((err) => {
        if (err.response && err.response.status < 500) {
          errorNotification(
            `Error getting clients, ${err.response.data.message}`
          );
        } else {
          errorNotification(`Error getting clients, reload to retry.`);
        }
        setClientLoading(false);
      });
  };

  const getUsers = () => {
    setUserLoading(true);
    axios
      .get(usersUrl, { headers: getHeaders() })
      .then((res) => {
        setUsers(res.data.data);
        setUserLoading(false);
      })
      .catch((err) => {
        if (err.response && err.response.status < 500) {
          errorNotification(
            `Error getting users, ${err.response.data.message}`
          );
        } else {
          errorNotification(`Error getting users, reload to retry.`);
        }
        setUserLoading(false);
      });
  };

  useEffect(() => {
    getClients();
    getUsers();

    // eslint-disable-next-line
  }, []);

  const handleSelectUpdate = (stateName) => (event) => {
    setState({ ...state, [stateName]: event.target.value });

    // remove form field errors
    if (errorMessage[stateName]) {
      setErrorMessage({
        ...errorMessage,
        [stateName]: null,
        message: null,
      });
    } else {
      setErrorMessage({
        ...errorMessage,
        message: null,
      });
    }
  };

  const handleFieldUpdate = (stateName) => (event) => {
    setState({ ...state, [stateName]: event.target.value });

    // remove form field errors
    if (errorMessage[stateName]) {
      setErrorMessage({
        ...errorMessage,
        [stateName]: null,
        message: null,
      });
    } else {
      setErrorMessage({
        ...errorMessage,
        message: null,
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    axios
      .post(businessUnitsUrl, state, { headers: getHeaders() })
      .then((res) => {
        setLoading(false);
        successNotification("Business Unit Created Successfully");
        props.history.push("/business-units");
      })
      .catch((err) => {
        setLoading(false);
        if (err.response && err.response.status < 500) {
          setErrorMessage({
            ...err.response.data.errors,
            message: err.response.data.message,
          });
        } else {
          setErrorMessage({
            ...errorMessage,
            message: ["Oops something went wront, kindly retry"],
          });
        }
      });
  };

  return (
    <PageLayout pages={pages}>
      <div className="container py-5 px-5 mx-auto">
        <form
          onSubmit={() => handleSubmit}
          className="shadow overflow-hidden sm:rounded-md mt-6 px-10 py-10 bg-white"
        >
          <div className="grid grid-cols-2 gap-10 mt-5">
            <div className="">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Business Unit Name
              </label>
              <input
                type="text"
                name="name"
                id="name"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                value={state.name}
                onChange={handleFieldUpdate("name")}
                required
                placeholder=""
              />
              {errorMessage.name
                ? errorMessage.name.map((err, i) => (
                    <p className="text-red-900 mt-2" key={i}>
                      {err}
                    </p>
                  ))
                : null}
            </div>

            <div>
              <label
                htmlFor="client_id"
                className="block text-sm font-medium text-gray-700"
              >
                Company{" "}
                {clientLoading ? (
                  <small className="ml-5 bg-green-200 px-2 py-1 mb-1 rounded-md text-green-900">
                    Loading .. <LoadingOutlined />
                  </small>
                ) : null}
              </label>
              <select
                id="client_id"
                name="client_id"
                required
                onChange={handleSelectUpdate("client_id")}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="">---- Choose ----</option>
                {clients.map((client) => (
                  <option key={client.id} value={client.id}>
                    {client.name}
                  </option>
                ))}
              </select>

              {errorMessage.client_id
                ? errorMessage.client_id.map((err, i) => (
                    <p className="text-red-900 mt-2" key={i}>
                      {err}
                    </p>
                  ))
                : null}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-10 mt-5">
            <div>
              <label
                htmlFor="user_id"
                className="block text-sm font-medium text-gray-700"
              >
                User{" "}
                {userLoading ? (
                  <small className="ml-5 bg-green-200 px-2 py-1 mb-1 rounded-md text-green-900">
                    Loading .. <LoadingOutlined />
                  </small>
                ) : null}
              </label>
              <select
                id="user_id"
                name="user_id"
                required
                onChange={handleSelectUpdate("user_id")}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="">---- Choose ----</option>
                {users.map((client) => (
                  <option key={client.uuid} value={client.uuid}>
                    {client.full_name}
                  </option>
                ))}
              </select>

              {errorMessage.user_id
                ? errorMessage.user_id.map((err, i) => (
                    <p className="text-red-900 mt-2" key={i}>
                      {err}
                    </p>
                  ))
                : null}
            </div>

            <div className="">
              <label
                htmlFor="slack_channel_id"
                className="block text-sm font-medium text-gray-700"
              >
                Slack Channel ID
              </label>
              <input
                type="text"
                name="slack_channel_id"
                id="slack_channel_id"
                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                value={state.slack_channel_id}
                onChange={handleFieldUpdate("slack_channel_id")}
                required
                placeholder=""
              />
              {errorMessage.slack_channel_id
                ? errorMessage.slack_channel_id.map((err, i) => (
                    <p className="text-red-900 mt-2" key={i}>
                      {err}
                    </p>
                  ))
                : null}
            </div>
          </div>

          {errorMessage.message ? (
            <p style={{ color: "red", marginTop: "1em" }}>
              {errorMessage.message}
            </p>
          ) : null}

          <div className="py-3 text-right mt-4 flex flex-row items-center gap-5 justify-end">
            <button
              type="button"
              className="inline-flex justify-center py-2 px-4 border border-gray-500 shadow-sm text-sm font-medium rounded-md text-gray-500 bg-white hover:bg-gray-200 focus:outline-none"
              onClick={() => props.history.push("/business-units")}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleSubmit}
              disabled={loading ? true : false}
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save Business Unit {loading ? <LoadingOutlined /> : null}
            </button>
          </div>
        </form>
      </div>
    </PageLayout>
  );
};

export default Create;
