import AWS from "aws-sdk";
// import formidable from "formidable-serverless";
// import fs from "fs";

export const FileUploader = (file, sendResponse) => {
  // async (req, res) => {

  // create S3 instance with credentials
  const s3 = new AWS.S3({
    endpoint: new AWS.Endpoint("nyc3.digitaloceanspaces.com"),
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: "nyc3",
  });

  // parse request to readable form
  // const form = new formidable.IncomingForm();
  // form.parse(req, async (err, fields, files) => {
  // Account for parsing errors
  //   if (err) return res.status(500);
  // Read file
  //   const file = fs.readFileSync(files.file.path);
  // Upload the file
  s3.upload(
    {
      // params
      Bucket: process.env.REACT_APP_AWS_STORAGE_BUCKET_NAME,
      ACL: "public-read",
      Key: generateRandomId(15),
      Body: file,
      ContentType: "image/jpeg",
    },
    undefined,
    (err, data) => sendResponse(err, data)
  );

  // .send((err, data) => {
  //   if (err) {
  //     //   console.log("err", err);
  //     return { status: 500, data: err };
  //   }
  //   if (data) {
  //     //   console.log("data", data);
  //     return { status: 200, url: data.Location };
  //   }
  // });
  // });
  //   };
};

export const generateRandomId = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
